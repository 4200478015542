import { Octokit } from "@octokit/rest";
import { log } from "./logger";

const delay = (n: number) =>  new Promise((r) => setTimeout(r, n))

export const createClient = (owner: string, repo: string, token: string) => {
  const octokit = new Octokit({ auth: token });
  return {
    async fetchLatestArtifact(run_id: number) {
      const res = await octokit.actions.listWorkflowRunArtifacts({
        owner,
        repo,
        run_id,
        per_page: 100,
      });
      log.debug("listWorkflowRunArtifacts result ", res);
      const { artifacts, total_count } = res.data;
      if (total_count < 100) {
        const latest = artifacts[artifacts.length - 1];
        return latest;
      }
      const page = ~~(total_count / 100);
      const last = await octokit.actions.listWorkflowRunArtifacts({
        owner,
        repo,
        run_id,
        per_page: 100,
        page,
      });
      return last.data.artifacts[last.data.artifacts.length - 1];
    },
    async downloadArtifact(artifactId: number) {
      return fetch(
        `https://api.github.com/repos/${owner}/${repo}/actions/artifacts/${artifactId}/zip`,
        {
          method: "GET",
          headers: {
            accept: "application/vnd.github.v3+json",
            authorization: `token ${token}`,
          },
        }
      );
    },
  };
};
