const WORKER_URL =
  process.env.NODE_ENV === "prod"
    ? "https://login.reg-actions.workers.dev"
    : "https://login-dev.reg-actions.workers.dev";

export const toLoginPage = () => {
  location.href = WORKER_URL;
};

async function login(code: string) {
  // remove ?code=... from URL
  const path =
    location.pathname +
    location.search.replace(/\bcode=\w+/, "").replace(/\?$/, "");
  history.pushState({}, "", path);

  try {
    const response = await fetch(WORKER_URL, {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ code }),
    });

    const result = await response.json();

    if (result.error) {
      return alert(JSON.stringify(result, null, 2));
    }

    return result.token;
  } catch (error) {
    location.reload();
  } finally {
  }
}

export const getToken = async (): Promise<string> => {
  const code = new URL(location.href).searchParams.get("code");

  if (code) {
    const token = await login(code);
    return token;
  } else {
    toLoginPage();
  }
};
