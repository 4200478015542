import * as path from "path";
import { inflateRawSync } from "zlib";

import { getToken } from "./auth";
import { crc32 } from "./crc32";
import { log } from "./logger";
import { createResolvable, Resolvable } from "./resolvable";
import { createClient } from "./client";

import {
  REPORT_JSON_NAME,
  ZIP_LOCAL_HEADER_SIZE,
  ZIP_NAME_LENGTH_OFFSET,
  ZIP_DATA_DESCRIPTOR_SIZE,
} from "./constants";

const storageKey = "reg-actions";
const retryDelay = 1000;

const createItems = (
  images: string[],
  resolverMap: { [k: string]: Resolvable<string> },
  dirnames: { actual: string; expected: string; diff: string }
) => {
  return images.map((img) => {
    return {
      type: "async",
      raw: img,
      encoded: encodeURIComponent(img),
      diffResolver: resolverMap[path.join(dirnames.diff, img)],
      actualResolver: resolverMap[path.join(dirnames.actual, img)],
      expectedResolver: resolverMap[path.join(dirnames.expected, img)],
    };
  });
};

const validateName = (name: string | null): boolean => {
  if (!name) return false;
  return /^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/i.test(name);
};

const validateRepo = (name: string | null): boolean => {
  if (!name) return false;
  return /[A-Za-z0-9_.-]+/.test(name);
};

const validateRunId = (id: number): boolean => {
  if (!id) return false;
  return Number.isInteger(id);
};

const notFound = () => {
  const error = document.querySelector("#error") as HTMLDivElement;
  error.innerText = "Not Found";
  error.style.display = "flex";
  error.style.alignItems = "center";
  error.style.justifyContent = "center";
  error.style.color = "#ccc";
  error.style.fontSize = "40px";
  error.style.fontWeight = "bold";
};

const authError = () => {
  const error = document.querySelector("#error") as HTMLDivElement;
  error.innerText = "Authorization error";
  error.style.display = "flex";
  error.style.alignItems = "center";
  error.style.justifyContent = "center";
  error.style.color = "#ccc";
  error.style.fontSize = "40px";
  error.style.fontWeight = "bold";

  const message = document.querySelector("#error-message") as HTMLDivElement;
  message.style.display = "block";
};

const unhandledError = (m: string) => {
  const error = document.querySelector("#error") as HTMLDivElement;
  error.innerText = "Unhandled error";
  error.style.display = "flex";
  error.style.alignItems = "center";
  error.style.justifyContent = "center";
  error.style.color = "#ccc";
  error.style.fontSize = "40px";
  error.style.fontWeight = "bold";

  const message = document.querySelector("#error-message") as HTMLDivElement;
  message.style.display = "block";
  message.innerText = m;
};

const delay = (n: number) => {
  return new Promise((r) => setTimeout(r, n));
};

const init = async () => {
  const searchParams = new URLSearchParams(location.search);

  const owner = searchParams.get("owner");
  const repo = searchParams.get("repository");
  const runId = Number(searchParams.get("run_id"));
  let id = searchParams.get("id");
  if (id) {
    id = encodeURIComponent(id);
  }

  const hasCode = !!searchParams.get("code");

  if (!hasCode) {
    if (!validateRunId(runId) || !validateName(owner) || !validateRepo(repo)) {
      log.error("Failed to validate params.");
      notFound();
      return;
    }
  }
  if (owner && repo && runId) {
    sessionStorage.setItem(
      storageKey,
      JSON.stringify({ owner, repo, runId, id })
    );
  }
  const token = await getToken();

  const params = sessionStorage.getItem(storageKey);
  const sessionParams = JSON.parse(params) as {
    owner: string;
    repo: string;
    runId: number;
    id: string | null;
  };

  if (
    !params ||
    !validateRunId(sessionParams.runId) ||
    !validateName(sessionParams.owner) ||
    !validateRepo(sessionParams.repo)
  ) {
    notFound();
    return;
  }

  const s = new URLSearchParams();
  s.set("owner", sessionParams.owner);
  s.set("repository", sessionParams.repo);
  s.set("run_id", String(sessionParams.runId));
  const path =
    location.pathname +
    "?" +
    s.toString() +
    (sessionParams.id ? `&id=${sessionParams.id}` : "");
  history.pushState({}, "", path);

  const client = createClient(sessionParams.owner, sessionParams.repo, token);

  let latest;
  try {
    latest = await client.fetchLatestArtifact(sessionParams.runId);
  } catch (e) {
    log.error(e);
    const message = e.toString();
    if (
      message.includes(
        "HttpError: Although you appear to have the correct authorization credentials"
      )
    ) {
      authError();
      return;
    }

    setTimeout(() => {
      unhandledError(message);
    }, 2000);

    return;
  }

  log.debug("Latest artifact = ", latest);

  if (!latest) {
    notFound();
    return;
  }

  const resolverMap = {};

  // Retry 3 times.
  // This is because sometimes fail to downloadArtifact.
  for (let i = 0; i < 3; i++) {
    try {
      const response = await client.downloadArtifact(latest.id);
      const reader = response.body.getReader();

      if (response.status >= 400) {
        // This is because sometimes github apu returns 403 error
        log.debug(`Failed to download artifact by code ${response.status}`);
        return;
      }

      let u8Arr = new Uint8Array();
      let ctx = {
        ptr: 0,
        current: "header",
        header: { name: "", nameLength: 0, extraLength: 0 },
        data: [],
      };
      sessionStorage.removeItem(storageKey);
      return new ReadableStream({
        start(controller) {
          // The following function handles each data chunk
          async function push() {
            // "done" is a Boolean and value a "Uint8Array"
            const { done, value } = await reader.read();
            // If there is no more data to read
            if (done) {
              log.debug("Readable stream completed.", value);
              controller.close();
              return;
            }
            log.debug("Received chunk is ", value);
            // Get the data and send it to the browser via the controller
            controller.enqueue(value);
            // Check chunks by logging to the console
            const newU8Arr = new Uint8Array(
              u8Arr.byteLength + value.byteLength
            );
            newU8Arr.set(u8Arr, 0);
            newU8Arr.set(value, u8Arr.byteLength);
            u8Arr = newU8Arr;

            while (ctx.ptr < u8Arr.byteLength) {
              if (u8Arr.byteLength - ctx.ptr < ZIP_LOCAL_HEADER_SIZE) {
                break;
              }
              const buf = Buffer.from(u8Arr.buffer);
              if (ctx.current === "header") {
                const s = ctx.ptr + ZIP_NAME_LENGTH_OFFSET;
                const nameLength = buf.readUInt16LE(s);
                ctx.header.nameLength = nameLength;
                const extraLength = buf.readUInt16LE(ctx.ptr + 28);
                ctx.header.extraLength = extraLength;
                ctx.current = "name";
                ctx.ptr = ctx.ptr + ZIP_LOCAL_HEADER_SIZE;
              }

              if (ctx.current === "name") {
                if (u8Arr.byteLength - ctx.ptr < ctx.header.nameLength) {
                  break;
                }
                const name = new TextDecoder().decode(
                  buf.slice(ctx.ptr, ctx.ptr + ctx.header.nameLength)
                );
                log.debug("Filename is ", name);
                ctx.current = "data";
                ctx.header.name = name;
                ctx.ptr = ctx.ptr + ctx.header.nameLength; // + extraLength;
              }

              if (ctx.header.name.endsWith("/")) {
                ctx = {
                  ...ctx,
                  current: "header",
                  header: { name: "", nameLength: 0, extraLength: 0 },
                  data: [],
                };
                continue;
              }

              if (ctx.current === "data") {
                log.debug("DataState", ctx);
                while (u8Arr[ctx.ptr] !== undefined) {
                  // Data descriptor signature
                  if (
                    u8Arr[ctx.ptr] === 0x50 &&
                    u8Arr[ctx.ptr + 1] === 0x4b &&
                    u8Arr[ctx.ptr + 2] === 0x07 &&
                    u8Arr[ctx.ptr + 3] === 0x08
                  ) {
                    if (u8Arr.byteLength - ctx.ptr < ZIP_DATA_DESCRIPTOR_SIZE) {
                      log.debug("There is no data descriptor yet.");
                      break;
                    }
                    const crc = buf.readUInt32LE(ctx.ptr + 4);
                    const compressedSize = buf.readUInt32LE(ctx.ptr + 8);
                    if (ctx.data.length !== compressedSize) {
                      ctx.data.push(u8Arr[ctx.ptr]);
                      ctx.ptr++;
                      continue;
                    }
                    const uncompressed = inflateRawSync(Buffer.from(ctx.data));
                    if (crc !== crc32(uncompressed)) {
                      ctx.data.push(u8Arr[ctx.ptr]);
                      ctx.ptr++;
                      continue;
                    }

                    if (ctx.header.name === REPORT_JSON_NAME) {
                      const report = JSON.parse(
                        new TextDecoder().decode(uncompressed)
                      );
                      log.info("Reg report json is ", report);

                      report.diffItems.forEach((item) => {
                        resolverMap[`${report.diffDir}/${item}`] =
                          createResolvable<string>();
                      });

                      report.actualItems.forEach((item) => {
                        resolverMap[`${report.actualDir}/${item}`] =
                          createResolvable<string>();
                      });

                      report.expectedItems.forEach((item) => {
                        resolverMap[`${report.expectedDir}/${item}`] =
                          createResolvable<string>();
                      });

                      const dirnames = {
                        actual: report.actualDir,
                        expected: report.expectedDir,
                        diff: report.diffDir,
                      };

                      const failedItems = createItems(
                        report.failedItems,
                        resolverMap,
                        dirnames
                      );

                      const passedItems = await createItems(
                        report.passedItems,
                        resolverMap,
                        dirnames
                      );

                      const newItems = await createItems(
                        report.newItems,
                        resolverMap,
                        dirnames
                      );

                      const deletedItems = await createItems(
                        report.deletedItems,
                        resolverMap,
                        dirnames
                      );

                      window["__reg__"] = {
                        owner: sessionParams.owner,
                        repo: sessionParams.repo,
                        runId: sessionParams.runId,
                        type: !!failedItems.length ? "danger" : "success",
                        hasNew: !!newItems.length,
                        newItems,
                        hasDeleted: !!deletedItems.length,
                        deletedItems,
                        hasPassed: !!passedItems.length,
                        passedItems,
                        hasFailed: !!failedItems.length,
                        failedItems,
                        actualDir: "",
                        expectedDir: "",
                        diffDir: "",
                      };

                      const script = document.createElement("script");
                      script.src = "./report.js";
                      document.body.appendChild(script);
                    } else {
                      log.debug(`Filename is ${ctx.header.name}`);
                      const t = ctx.header.name.split(".");
                      const imageType = t[t.length - 1];
                      const blob = new Blob([uncompressed], {
                        type: `image/${imageType}`,
                      });
                      const blobUrl = URL.createObjectURL(blob);
                      log.debug("blobURL is", blobUrl);
                      log.debug(ctx.header.name, resolverMap);
                      if (resolverMap[ctx.header.name]) {
                        resolverMap[ctx.header.name].resolve(blobUrl);
                      }
                    }

                    ctx.ptr = ctx.ptr + ZIP_DATA_DESCRIPTOR_SIZE;
                    ctx = {
                      ...ctx,
                      current: "header",
                      header: { name: "", nameLength: 0, extraLength: 0 },
                      data: [],
                    };
                    break;
                  }
                  ctx.data.push(u8Arr[ctx.ptr]);
                  ctx.ptr++;
                }
              }
            }
            push();
          }
          push();
        },
      });
    } catch (e) {
      log.error(e);
      await delay(retryDelay);
    }
  }
};

init();
