export const crc32 = (buf: Buffer) => {
  const table = [];
  const poly = 0xedb88320;
  let result = 0xffffffff;

  for (let i = 0; i < 256; i++) {
    let u = i;
    for (let j = 0; j < 8; j++) {
      if (u & 0x1) u = (u >>> 1) ^ poly;
      else u >>>= 1;
    }
    table.push(u >>> 0);
  }
  for (let i = 0; i < buf.length; i++) {
    result = ((result >>> 8) ^ table[buf[i] ^ (result & 0xff)]) >>> 0;
  }
  return ~result >>> 0;
};
